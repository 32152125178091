/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "custom_buttons";
@import "custom_forms";
@import "custom_treeview";
@import "custom_tables";
@import "custom_utility";
@import "custom_skeleton";
@import "custom_toast";
@import "custom_p-tree";
@import "bootstrap-css-validate-uncompress";
@import "validate";

html, body {
  font-size: 0.95rem;
  //background-color: #eff3f7;
  //background-color: #f5f5f5;
  //overflow: hidden;
}

main {
  .card {
    border: none;
    border-radius: 10px;
  }
}

span.badge.rounded-pill {
  padding-top: 0.4rem;
}

ngb-pagination .page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

ngb-pagination .page-item .page-link {
  border: 0;
  border-radius: 5px;
}

ngb-pagination .page-item:not(.active):not(.disabled) .page-link {
  color: #62b5fb;
}

ngb-pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #62b5fb;
  border-color: #62b5fb;
}

ngb-pagination .page-item.disabled .page-link {
  background-color: #eeeeee;
}

ngb-pagination ul.pagination {
  margin: 0;
}

.custom-badge {
  padding: 0.3em 0.4em;
  font-size: 90%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  border-radius: 0.5rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &.bg-primary-lt {
    color: $primary !important;
    background-color: #e1ecff !important;
  }

  &.bg-info-lt {
    color: #1094b5 !important;
    background-color: #d9f4fb !important;
  }

  &.bg-warning-lt {
    color: #cba20a !important;
    border: 1px solid #fdf6d9 !important;
    background-color: #fdf6d9 !important;
  }

  &.bg-success-lt {
    //color: #198754 !important;
    //color: #a3cfbb !important;
    color: #ffffff !important;
    border: 1px solid #13ce66 !important;
    background-color: #13ce66 !important;
    //background-color: #d1e7dd !important;
  }

  &.bg-danger-lt {
    color: #f21378 !important;
    background-color: #fde1ee !important;
  }

  &.bg-default-lt {
    color: #3e455a !important;
    background-color: white !important;
  }

  &.bg-secondary-lt {
    color: #3e455a !important;
    background-color: #e3e5e9 !important;
  }

  &.bg-dark-lt {
    color: #090c16 !important;
    background-color: #dadbdf !important;
  }

  &.bg-light-lt {
    background-color: #f5f5f5;
    color: #4f4f4f;
  }

  &.bg-warning2-lt {
    background-color: #ffe8df !important;
    color: #e55a09 !important;
  }
}

.custom-text-bg-primary {
  color: #116bff !important;
  background-color: #e1ecff !important;
}

.custom-text-bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.custom-text-bg-secondary {
  color: #3e455a !important;
  background-color: #e3e5e9 !important;
}

.custom-text-bg-danger {
  color: #f21378 !important;
  background-color: #fde1ee !important;
}

.custom-bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.custom-bg-danger {
  color: #f21378 !important;
  background-color: #fde1ee !important;
}


.bg-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.alert-warning {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

//.table-primary {
//  --bs-table-color: white;
//  --bs-table-bg: $primary;
//  background: $primary;
//  color: white;
//}
.table-secondary-subtle {
}

.custom-icon-remove {
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
