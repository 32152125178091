.custom-toast-success {
  position: fixed;
  top: auto !important;
  //bottom: 0 !important;
  right: 10px !important;
  left: auto !important;
  margin: 0.5em;
  z-index: 11;

  .toast-header {
    color: white !important;
    background-color: #13ce66 !important;
    border-bottom: 0;
  }

  .toast-body {
    color: white !important;
    background-color: #13ce66 !important;
  }
}

.custom-toast-danger {
  position: fixed;
  top: auto !important;
  //bottom: 0 !important;
  right: 10px !important;
  left: auto !important;
  margin: 0.5em;
  z-index: 11;
}

.custom-toast-warning {
  position: fixed;
  top: auto !important;
  //bottom: 0 !important;
  right: 10px !important;
  left: auto !important;
  margin: 0.5em;
  z-index: 11;

  .toast-body {
    color: #cba20a !important;
    background-color: #fdf6d9 !important;
  }
}
