.w-15 {
  width: 15% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75px {
  width: 75px;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-150px {
  width: 150px;
}

.w-160px {
  width: 160px;
}

.w-175px {
  width: 175px;
}

.w-200px {
  width: 200px;
}

.w-400px {
  width: 400px;
}

.minw-50px {
  min-width: 50px;
}

.minw-100px {
  min-width: 100px;
}

.minw-160px {
  min-width: 160px;
}


.minw-180px {
  min-width: 180px;
}

.minw-250px {
  min-width: 250px;
}

.minw-300px {
  min-width: 300px;
}

.minw-350px {
  min-width: 350px;
}

.minw-400px {
  min-width: 400px;
}

.minw-500px {
  min-width: 500px;
}

.maxw-65px {
  max-width: 65px;
}

.maxw-50px {
  max-width: 50px;
}

.maxw-75px {
  max-width: 75px;
}

.maxw-125px {
  max-width: 125px;
}

.maxw-200px {
  max-width: 200px;
}

.maxw-250px {
  max-width: 250px;
}

.maxw-260px {
  max-width: 260px;
}

.maxw-450px {
  max-width: 450px;
}

.maxw-500px {
  max-width: 500px;
}

.maxw-600px {
  max-width: 600px;
}

.maxw-700px {
  max-width: 700px;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;

  .row-flex-col {
    display: flex;
    flex-direction: column;
  }
}

.c-pointer {
  cursor: pointer;
}
