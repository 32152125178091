@import "variables";

.btn-default {
  --bs-btn-hover-bg: #{shade-color($default, 10%)};
  --bs-btn-hover-border-color: #{shade-color($default, 10%)};
  --bs-btn-active-bg: #{shade-color($active, 20%)};
  --bs-btn-active-border-color: #{shade-color($active, 20%)};
}

.btn-action {
  --bs-btn-hover-bg: #{shade-color($active, 10%)};
  --bs-btn-hover-border-color: #{shade-color($active, 10%)};
  --bs-btn-active-bg: #{shade-color($active, 20%)};
  --bs-btn-active-border-color: #{shade-color($active, 20%)};
}

.btn-secondary {
  --bs-btn-hover-bg: #{shade-color($secondary, 10%)};
  --bs-btn-hover-border-color: #{shade-color($secondary, 10%)};
  --bs-btn-active-bg: #{shade-color($secondary, 20%)};
  --bs-btn-active-border-color: #{shade-color($secondary, 20%)};
}

.btn-success {
  --bs-btn-hover-bg: #{shade-color($success, 10%)};
  --bs-btn-hover-border-color: #{shade-color($success, 10%)};
  --bs-btn-active-bg: #{shade-color($success, 20%)};
  --bs-btn-active-border-color: #{shade-color($success, 20%)};
}

.btn-info {
  --bs-btn-hover-bg: #{shade-color($info, 10%)};
  --bs-btn-hover-border-color: #{shade-color($info, 10%)};
  --bs-btn-active-bg: #{shade-color($info, 20%)};
  --bs-btn-active-border-color: #{shade-color($info, 20%)};
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: white !important;
  background: linear-gradient(270deg, hsla(207, 95%, 68%, 1) 0%, hsla(207, 95%, 56%, 1) 100%) !important;;
}

.btn-success,
.btn-success:active,
.btn-success:focus {
  color: white !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  //color: white !important;
}

.btn-outline-primary:focus {
  //color: #0975F1FF !important;
  color: $primary;
  background: white;
}

.btn-warning,
.btn-warning:active,
.btn-warning:focus {
  color: #cba20a !important;
  background-color: #fdf6d9 !important;
}

.btn-outline-warning:hover,
.btn-outline-warning:active,
.btn-outline-warning:focus {
  color: #cba20a !important;
}


.btn-default,
.btn-default:active,
.btn-default:focus {
  transition: .4s;
  border: 1px solid #bfbfc3 !important;
  color: #333238;
}

.btn-active,
.btn-active:active,
.btn-active:focus {
  transition: .4s;
  border: 1px solid $active !important;
  color: #333238;
  background-color: #767676;
}

.btn-orange {
  border: 1px solid #ff6600 !important;
  background: #ff6600 !important;
  color: #ffffff !important;
}

.btn-action,
.btn-action:active,
.btn-action:focus {
  transition: .4s;
  background: #efefef !important;
  border: 1px solid #bfbfc3 !important;
  color: #333238;
}

.btn-primary {
  --bs-btn-color: white !important;
}

.btn-info,
.btn-info:active,
.btn-info:focus {
  //color: $primary;
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  //border: 0;
}

.btn-custom {
  min-width: 120px;
  //border-radius: 50rem !important;
}

.label-button {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.label-default {
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  //border: 1px solid #FFF;
  //--bs-btn-hover-bg: #{shade-color($default, 10%)};
  //--bs-btn-hover-border-color: #{shade-color($default, 10%)};
  //--bs-btn-active-bg: #{shade-color($default, 20%)};
  //--bs-btn-active-border-color: #{shade-color($default, 20%)};
  border: 1px solid #bfbfc3 !important;
  color: #333238;
}

.btn-default2,
.btn-default2:active,
.btn-default2:focus {
  transition: .4s;
  border: 1px solid #bfbfc3 !important;
  color: #333238;
}
